/* CSS for the Artist page */
.artist-app {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-areas:
    "artist-list artist-details-and-results";
}

/* navigation between artists */
.artist-list {
  grid-area: artist-list;
  height: 70vh;
  height: 70svh;
  width: 15vw;
  /* width: clamp(15rem, 15vw, 20rem); */
  padding-top: 10vh;
  padding-top: 10svh;
    /* border: .1rem solid black; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;  
}

.artist-list-button {
  font-size: 1rem;
  width: clamp(5rem, 100%, 15vw);
  background-color: var(--main);
  text-align: left;
  text-decoration: none;
  padding: 0;
  margin: 0;
  border:none;
  margin-top: .5em;
  margin-bottom: .5em;
  margin-left: .5em;
}

.artist-list-button:focus{
  /* background-color: var(--main-text); */
  background-color: var(--highlight);
  color: var(--main);
  text-decoration: underline;
  /* font-size: 1.25em; */
}



/* column layout for artist details then art */
.artist-details-and-results {
  grid-area: artist-details-and-results;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}

/* artist name and twitter link */
.artist-details {
  padding-top: 0;
  margin-top: 0;
  word-wrap: break-word;
}

.artist-name {
  padding-top: 0;
  margin-top: 0;
  margin-left:1rem;
  margin-bottom: 1rem;

}

.artist-socials{
  display: flex;
  flex-direction: row;
  padding-left: 1em;
  width:10vw;
  justify-content: space-between;
  /* flex-wrap: nowrap; */
}

.artist-twitter {
  display: flex;
  flex-direction: row;
  padding-top: 0;
  margin-top: 0;
  padding-left: .25em;
  padding-right: .25em;

}

.artist-os{
  display: flex;
  flex-direction: row;
  justify-content: baseline;
}
.artist-socials > a{
  margin: .25em;
  width: 2em;
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.artist-socials > a > svg{
  width:auto;
  scale: 2.5;
}

/* artists art collection */
.artist-results {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 85vw;
  height: 70vh;
  height: 70svh;
  overflow-y: scroll;
}


@media screen and (min-width: 20rem) and (max-width: 63rem) {
  .artist-app {
    grid-template-columns: 100%;
    grid-template-areas:
      "artist-list"
      "artist-details-and-results";
    height: auto;  
  }

  .artist-list {

    height: 7.5vh;
    height: 7.5svh;
    overflow-y: scroll;
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 5vh;
    padding-top: 5svh;
    /* background-color: var(--main-text); */
  }

  .artist-list-button {
    background-color: var(--main-text);
    color: var(--main);
    width: clamp(10rem, 20rem, 25vw);
    padding: 0.5em;
  }

  .artist-list-button:focus {
    background-color: var(--main);
    color: var(--main-text);
  }

  .artist-details-and-results {
    width: 95vw;
    width: 95svw;
  }

  .artist-details {
    padding-top: 0;
    margin-top: 0;
    display: flex;
    flex-direction: row;
  }

  .artist-name {
    padding: 0;
    margin: 0;
  }

  .artist-twitter {
    padding: 0;
    margin: 0;
  }

  .artist-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 95vw;
    overflow-y: scroll;
  }

  .artist-socials > a > svg{
    width:auto;
    scale: 1.5;
  }
}