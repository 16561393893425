/* CSS for the Search page */
.search-app {
    width: 100vw;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    grid-template-areas:
      ". search-bar ."
      ". list-results .";
  }
  
  .search-bar {
    grid-area: search-bar;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 5vh;
    width: 70vw;
    padding-bottom: 10vh;
  }
  
  .search__input {
    width: 50vw;
  }
  
  .search__button {
    width: 20vw;
  }
  
  .list-results {
    grid-area: list-results;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 70vw;
  }
  
  .list-results-card {
    border-bottom: thick solid var(--main);
    padding-top: 2.5vh;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    min-height: 45vw;
    max-height: 45vw;
  }
  
  .list-results-card-media {
    width: 20vw;
  }
  
  .list-results-card-description {
    width: 25vw;
    white-space: normal;
    overflow: auto;
    text-overflow: ellipsis;
    max-height: 7.5vw;
  }
  
  .list-results-card-model-view-css-fix>model-viewer {
    padding: 0;
    margin: 0;
    /* UI bug on the model viewer where I had to adjust height by sight */
    height: 26.5vw;
    width: 20vw;
  }
  
  .list-results-card-scroll-arrow {
    height: 50px;
  }
  
  .list-results>div {
    height: 2.5vh;
  }
  
  @media screen and (min-width: 20rem) and (max-width: 63rem) {
    .search-app {
      grid-template-columns: 5% 90% 5%;
    }
    .list-results {
      flex-direction: column;
      width: 90vw;
    }    
    .list-results-card {
      width: 100%;
      min-height: 90vh;
    }
    /* maintaining the original media aspect ratio should be part of the dataset */
    .list-results-card-media {
      height: 40vh;
      width: 30vh;
    }
    
    .list-results-card-description {
      font-size: 1em;
      width: 80vw;
      max-height: 25vh;
      max-height: 25svh;
    }
    
    .list-results-card-model-view-css-fix>model-viewer {
      padding: 0;
      margin: 0;
      /* UI bug on the model viewer where I had to adjust height by sight */
      height: 26.5vw;
      width: 20vw;
    }
    
    .list-results-card-scroll-arrow {
      height: 1em;
    }
    .list-results-card-type{
      font-size: 1.25em;
    }
    /* .list-results>div {
      height: 2.5vh;
    }         */
  }