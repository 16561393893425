.card-viewer {
    grid-area: card-viewer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 85vw;
    height: 70vh;
    overflow-y: scroll;
    justify-content: flex-start;
    /* align-items: center; */
    background-color: var(--main);
  }
  
  /* .card-viewer-container {
    padding: 0;
    margin: 0;
    height: 70vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */
  .card-viewer-card-holder {
    padding: 0;
    margin: 0;
    /* height: 70vh; removing height fixed 3d model viewer bug */
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-viewer-info {
    display: flex;
    flex-direction: row;
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 1vh;
    min-height: 2vh;
    max-height: 2vh;
  }
  .card-viewer-info > a {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: .5em;
    font-size: .85em;
    color: var(--main-text);
    /* text-shadow: .5px .5px var(--opposite); */
    /* allows the card infor the expand without a line break, the trade off is having evenly spaced cards */
    white-space: nowrap;
  }
  .card-viewer-info > a > svg{
    padding-left: .25em;
    transform: scale(1);
  }
  
  
  
  .card-viewer-image {
    object-fit: contain;
    padding: .5em;
    margin: .5em;
    height: 60vh;
    width: auto;
  }
  
  .card-viewer-video {
    object-fit: contain;
    padding: .5em;
    margin: .5em;
    height: 60vh;
    width: auto;
  }
  
  /* this container is needed to allow the 3d model to be the same size as the cards */
  .card-viewer>.model-view-css-fix {
    object-fit: contain;
    padding: .5em;
    margin: .5em;
    height: 60vh;
    width: auto;
  }
  /* Is this a style that can be on the main app css? */
  model-viewer {
    --progress-bar-color: var(--main-text);
    /* the width can not be 100% or auto therefore a 900/1200 ratio is kept by using vh */
    width: 45vh;
    height: 60vh;
    background-color: var(--main);
  }

  @media screen and (min-width: 20rem) and (max-width: 63rem) {
    .card-viewer-image {
        object-fit: contain;
        padding: .5em;
        margin: .5em;
        height: auto;
        width: 80vw;
      }
    
      .card-viewer {
        flex-wrap: nowrap;
        width: 100%;
        height: 80vh;
      }
    
      .card-viewer-video {
        object-fit: contain;
        padding: .5em;
        margin: .5em;
        height: 60vh;
        width: auto;
      }
    
      /* this container is needed to allow the 3d model to be the same size as the cards */
      .card-viewer>.model-view-css-fix {
        object-fit: contain;
        padding: .5em;
        margin: .5em;
        height: 60vh;
        width: auto;
      }
    
      model-viewer {
        --progress-bar-color: var(--main-text);
        /* the width can not be 100% or auto therefore a 900/1200 ratio is kept by using vh */
        width: 45vh;
        height: 60vh;
        background-color: var(--main);
      }
    
      .card-list-button {
        display: block;
        height: 30vw;
        min-width:40vw;
        white-space: break-spaces;
      }
    
      .card-selected {
        background-image: var(--card-list-bg);
        color: var(--middle);
      }
  }
  