/* Gallery page */
.gallery-app {
  width: 100%;
  display: grid;
  grid-template-columns: 15% 15% 15% 55%;
  grid-template-areas:
    ". gallery-article gallery-article ."
    "gallery-card-list gallery-card-viewer gallery-card-viewer gallery-card-viewer";
}

.gallery-article {
  grid-area: gallery-article;
}

.gallery-card-list {
  grid-area: gallery-card-list;
  height: 70vh;
  height: 70svh;
  overflow-x: scroll;
  width: clamp(5rem, 15vw, 50rem);
  margin-top: 1em;
}

.gallery-card-list-button {
  background-color: var(--main);
  border: none;
  padding: .25em .25em;
  text-align: left;
  text-decoration: none;
  margin: .05em .05em;
  width: clamp(30rem, 15vw, 50rem);
}

.gallery-card-list-button:focus {
  /* color: var(--main-text);
  text-decoration: underline; */
  /* background-color: var(--main-text); */
  background-color: var(--highlight);
  color: var(--main);
  text-decoration: underline;
}

.gallery-card-viewer {
  grid-area: gallery-card-viewer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 85vw;
  height: 70vh;
  height: 70svh;
  overflow-y: scroll;
  justify-content: flex-start;
  /* align-items: center; */
}

.gallery-card-viewer-card-holder {
  padding: 0;
  margin: 0;
  height: 70svh;
  height: 70vh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery-card-viewer-image,
.gallery-card-viewer-video,
.gallery-model-view-css-fix {
  object-fit: contain;
  padding: 1em;
  height: 60vh;
  width: auto;
}

.gallery-card-title {
  padding: 0;
  margin: 0;
}

/* this container is needed to allow the 3d model to be the same size as the cards */
/* .gallery-card-viewer > .model-view-css-fix {
  object-fit: contain;
  padding: 1em;
  height: 60vh;
  width: auto;
} */
@media screen and (min-width: 20rem) and (max-width: 63rem) {
  .gallery-app {
    grid-template-columns: 100%;
    grid-template-areas:
      "gallery-article"
      "gallery-card-list"
      "gallery-card-viewer";
  }

  .gallery-article {
    height: 5vh;
    height: 5svh;
  }

  .gallery-card-list {
    height: 7.5vh;
    height: 7.5svh;
    overflow-y: scroll;
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .gallery-card-viewer {
    height: 85vh;
    height: 85svh;
    width: 100%
  }

  .gallery-card-viewer-card-holder {
    height: 72.5vh;
    height: 72.5svh;
  }

  .gallery-card-viewer-image,
  .gallery-card-viewer-video,
  .gallery-model-view-css-fix {
    height: 70vh;
    height: 70svh;
  }

  .gallery-card-list-button {
    background-color: var(--main-text);
    color: var(--main);
    width: clamp(30rem, 25vw, 50rem);
  }

  .gallery-card-list-button:focus {
    background-color: var(--main);
    color: var(--main-text);
  }
}