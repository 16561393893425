/* About page */
.about-app {
    width: 100vw;
    display: grid;
    grid-template-columns: 15% 30% 10% 30% 15%;
    grid-template-areas:
      ". about-title about-title about-title ."
      ". about-article . about-image .";
  }
  
  .about-title {
    grid-area: about-title;
  }
  
  .about-article {
    grid-area: about-article;
    height: 40vw;
    width: 35vw;
  }
  
  .about-image {
    grid-area: about-image;
    height: 40vw;
    width: 30vw;
  }
  @media screen and (min-width: 20rem) and (max-width: 63rem) {
      /* About page */
  .about-app {
    grid-template-columns: 10% 80% 10%;
    grid-template-areas:
      ". about-title ."
      ". about-article ."
      ". about-image .";
  }

  .about-article {
    height: auto;
    width: 80vw;
  }

  .about-image {
    height: auto;
    width: 90vw;
  }
  } 