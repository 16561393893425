/* Application default styling */
/* npm install animate.css --save */
:root {
  /* colors */
  /* Lurker approved */
  --color-1: #F9D6CA;
  --color-2: white;
  --color-3: black;
  --color-4: #F26241;

  /* logical names */
  --main: var(--color-3);
  --main-text: var(--color-2);
  --highlight: var(--color-4)
}

/* Kevin Powell recommendations */
.container {
  height: 100vh;
  height: 100svh;
  width: clamp(1rem, 10vw, 2rem);
  font-size: clamp(1rem, 1.25rem, 2.5vw);
  margin: 1em;
  padding: 1em;
}

/* background */
body {
  background-color: var(--main);
}

/* Text */
h1,
h2,
h3,
p,
button,
a,
label, svg {
  color: var(--main-text);
  font-family: Roboto Mono;
}

h1 {
  font-size: 4em;
}

/* Header & Nav */
.app-header-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
}

.app-logo {
  width: clamp(15rem, 15vw, 15vw + 1em);
  height: auto;
}

.app-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: clamp(5rem, 15vh, 15rem);
}

.app-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: clamp(5rem, 50vw, 75rem);
  padding: 1.25em;
}

select {
  height: 26px;
  background-color: var(--main);
  color: var(--main-text);
  font-family: Roboto Mono;
  border-radius: .25em;
  border-color: var(--main-text);
}

.artist-nav{
  color:var(--highlight);
  text-decoration:underline; 
}

.merch-link{
  text-decoration: none
}

/* CSS for the mobile layout */
/* Only the changes that occur because of the media query are in the classes below */
@media screen and (min-width: 20rem) and (max-width: 63rem) {

  /* Header & Nav */
  .app-header {
    justify-content: center;
    height: clamp(5rem, 7.5vh, 15rem);
  }

  .app-nav {
    width: 50vw;
    flex-wrap: wrap;
    justify-content: space-around;
    height: clamp(5rem, 7.5vh, 15rem);
  }

  .app-logo {
    width: clamp(5rem, 35vw, 15rem);
    height: auto;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1em;
  }

}