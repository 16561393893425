/* Splash page */
.spash-page{
    margin:0;
    padding: 0;
    display: grid;
    grid-template-columns: 15% 30% 30% 15%;
    grid-template-areas: ". gallery heading .";
  }
  .splash-card-vertical-gallery{
    grid-area: gallery;
    height: 80vh;
    overflow-y: scroll;
    /* overflow: hidden; */
  }
  /* .splash-card-vertical-gallery::-webkit-scrollbar{
    display: none;
  } */
  .spash-text-container{
    grid-area: heading;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
  .spash-start-button{
    background-color: var(--main);
    font-size: 2em;
    border-color: var(--main-text);
    color: var(--main-text);
    border-style: solid;
    border-radius: .25em;
    text-decoration:none;
    padding: .5em;
    max-width: clamp(7em, 15em, 5vw);
  }

  @media screen and (min-width: 20rem) and (max-width: 63rem) {
    .spash-page{
      display: grid;
      grid-template-columns: 5% 90% 5%;
      grid-template-areas: ". heading .";      
    }  
    .splash-card-vertical-gallery{
      display: none;
    }
    .spash-text-container{
      height: 50svh;
      height: 50vh;
      align-items: center;
    }

  }
