/* CSS for the Card page */
.card-app {
    width: 100vw;
    display: grid;
    grid-template-columns: 10% 40% 40% 10%;
    grid-template-areas:
      ". card-results description-bar .";
  }
  
  .description-bar {
    grid-area: description-bar;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: space-around;
    align-items: left;
    align-content: center;
    text-align: left;
    height: 70vh;
    width: 40vw;
    padding-top: 7.5vh;
    padding-bottom: 15vh;
  }
  .description-type-and-term{
    width: 40vw;
  }
  .opensea-link > svg{
    transform: scale(1.5);
  }
  
  .description-type {
    width: 50vw;
    border-bottom: thick solid var(--main);
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .description-term {
    width: 50vw;
    padding-top: 0;
    margin-top: 0;
  }
  
  .card-results {
    grid-area: card-results;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 40vw;
  }
  
  .card-card {
    word-wrap: break-word;
    width:30vw;
  }
  .card-listing{
    text-decoration: none;
  }
  .description-links{
    width:25vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 15vh;
  }
  .description-links > a > svg{
    transform: scale(2.5);
  }

  @media screen and (min-width: 20rem) and (max-width: 63rem) {
    .card-app {
      width: 100vw;
      display: grid;
      grid-template-columns: 5% 90% 5%;
      grid-template-areas:
        ". card-results ."
        ". description-bar .";
    }
    .description-bar {
      align-content: center;
      text-align: center;
      height: auto;
      width:100%;
      /* margin-top:1em; */
    }
    /* .card-viewer-image{
      margin-bottom:1em;
    } */
    .description-type-and-term{
      width:100%
    }
    .card-results {
      /* width: 80vw; */
      width: 100%;
    }
    .card-card {
      /* width: 70vw; */
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }    
  }  