/* Submit page */
.submit-app {
    width: 100vw;
    display: grid;
    grid-template-columns: 15% 30% 10% 30% 15%;
    grid-template-areas:
      ". submit-title . . ."
      ". form . art .";
  }
  
  .submit-title {
    grid-area: submit-title;
  }
  
  .submit-iframe {
    grid-area: form;
    height: 40vw;
    width: 35vw;
  }
  
  .submit-image {
    grid-area: art;
    height: 40vw;
    width: 30vw;
  }
  
  @media screen and (min-width: 20rem) and (max-width: 63rem) {
  /* Submit page */
  .submit-app {
    grid-template-columns: 10% 80% 10%;
    grid-template-areas:
      ". submit-title ."
      ". form ."
      ". art .";
  }

  .submit-iframe {
    height: 85vh;
    width: 80vw;
  }

  .submit-image {
    display: none;
    height: 20vw;
    width: 15vw;
  }
  }