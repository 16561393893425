@media screen and (min-width: 20rem) and (max-width: 63rem) {
    .list-results {
        width: 80vw;
      }
      
      .list-results-card {
        width: 80vw;
        min-height: 150vw;
        max-height: 150vw;
      }
      
      .list-results-card-media {
        width: 70vw;
      }
      
      .list-results-card-description {
        width: 75vw;
        max-height: 30vw;
      }
      
      .list-results-card-model-view-css-fix>model-viewer {
        padding: 0;
        margin: 0;
        /* UI bug on the model viewer where I had to adjust height by sight */
        height: 40vh;
        width: 70vw;
      }
      
      .list-results-card-scroll-arrow {
        height: 50px;
      }
      
      .list-results>div {
        height: 2.5vh;
      }    
}